import { getRoleDaById } from "mocks/roleDA";
import moment from "moment";
import { ReportRecord } from "types/Report";

export const getTimeSincePost = (date: string) => {
  let currentDate = moment().format();
  let expiredDate = moment(date).add(2, "days").format();
  let currentDateInJsFormat = new Date(currentDate);
  let expiredDateInJsFormat = new Date(expiredDate);
  let currentDateInMs = currentDateInJsFormat.getTime();
  let expiredDateInMs = expiredDateInJsFormat.getTime();

  return currentDateInMs > expiredDateInMs;
};

export const getFilteredFormData = ({
  searchValue,
  currentReport,
}: {
  searchValue: string;
  currentReport: ReportRecord[];
}) => {
  return currentReport.filter((obj) => {
    const values = [
      getRoleDaById(obj.role),
      obj?.van?.fleet_size?.title,
      obj?.driver?.first_name,
      obj?.driver?.last_name,
      obj?.van?.van_group_name,
      obj?.van?.number,
      obj?.van?.van_group?.name,
      obj?.driver?.first_name,
      obj?.driver?.last_name,
      obj?.driver?.transporter_id === "none"
        ? "nr"
        : obj?.driver?.transporter_id || "pending the id",
      obj?.van?.van_group?.name,
      obj?.van?.van_group_name,
      obj?.van?.number,
      obj?.route,
      `${obj?.uta}`,
      `${obj?.utl}`,
      `${obj?.nsl}`,
      `${obj?.bc}`,
      `${obj?.fdd}`,
      `${obj?.missort}`,
      `${obj?.oc}`,
      `${obj?.number_of_stops}`,
      `${obj?.number_of_packages}`,
      `${obj?.rescue_given}`,
      `${obj?.rescue_taken}`,
      `${obj?.missing}`,
      `${obj?.damage}`,
      `${obj?.rabbit}`,
      `${obj?.dolly_serial_number}`,
      obj?.first_stop_time,
      obj?.planned_end_time,
      obj?.actual_end_time,
      obj?.actual_DA,
    ].filter((el) => el?.toLowerCase()?.includes(searchValue.toLowerCase())).length;
    return values > 0;
  });
};
