import { FC, useEffect, useRef, useState, ChangeEvent, FocusEvent, useCallback } from "react";
import {
  Checkbox,
  IconButton,
  Select,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DuplicateVansDialog, NoVansDialog } from "components/modals/modalContent/ShiftDialogs";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { roleDa, waveDa } from "mocks/roleDA";
import { inputNumberValidate, inputNumberValidateOnPaste } from "utils/formats";
import { getTimeSincePost } from "utils/filters";
import { ReportRecord, DriverData, FleetSizeType } from "types/Report";
import { useDispatch } from "react-redux";
import { getReportAction } from "actions/daDailyReportsAction";
import { useReportStyles } from "./styles";
import { useNav } from "hooks/useNav";
import api from "api";
import { Colors } from "constants/constants";
import { NumericFormat } from "react-number-format";
import { getCurrentAgency } from "hooks/getCurrentAgency";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { openModal } from "actions/modalActions";
import { OnBlurEvent } from "types/general";

interface ReportRowProps {
  reportRecord: ReportRecord;
  index: number;
  onDelete: (id: number) => void;
  onUpdate: (id: number, data: ReportRecord) => void;
}

type ReportRowValue = string | number | undefined | null;

export const ReportRow: FC<ReportRowProps> = ({ reportRecord, index, onDelete, onUpdate }) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const recordId = reportRecord.id;
  const reportId = useNav().pageParams?.id;
  const ref = useRef<HTMLInputElement | null>(null);

  const { userData } = useTypeSelectors((state) => state.auth);
  const { currentReportDate, currentReportStation, fleetSizes } = useTypeSelectors(
    (state) => state.dasBoard,
  );
  const [rowData, setRowData] = useState<ReportRecord>(reportRecord);
  const [duplicateVansData, setDuplicateVansData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<DriverData[]>([]);
  const [currentDriver, setCurrentDriver] = useState<DriverData | undefined>();
  const [disabledByDate, setDisabledByDate] = useState(getTimeSincePost(currentReportDate));
  const [driverVanAssignmentId, setDriverVanAssignmentId] = useState(rowData.assignments?.[0]?.id);
  const [codValue, setCodValue] = useState(rowData.cod);
  const renderTextTransporterId =
    currentReportStation?.id === 1 || currentReportStation?.id === 2 ? "Pending the ID" : "NR";
  const conditionForDisabledEdit = userData?.can_create_old_shifts ? false : disabledByDate;

  useEffect(() => {
    if (currentReportDate) {
      setDisabledByDate(getTimeSincePost(currentReportDate));
    }
  }, [currentReportDate]);

  const handleDeleteRow = () => onDelete(recordId);

  const handleUpdateRow = useCallback(
    (data: any) => {
      setRowData({ ...rowData, ...data });
      api
        .updateReportRecord(recordId, data)
        .then((res) => {
          if (res.data) {
            onUpdate(recordId, res.data);
            setRowData({ ...rowData, ...res.data });
          }
        })
        .catch((err) => {
          onUpdate(recordId, rowData);
          setRowData(rowData);
          if (ref.current !== null) ref.current.value = "";
          if (err?.response?.data?.detail) {
            setDuplicateVansData(err?.response?.data?.detail);
            dispatch(openModal(`duplicate-vans-modal-${recordId}`));
          }
        });
    },
    [dispatch, onUpdate, recordId, rowData],
  );

  const handleClearDrivers = () => {
    setDrivers([]);
    setLoading(false);
  };

  const handleGetDrivers = async () => {
    setLoading(true);
    api
      .getAvailableDrivers({
        date: currentReportDate,
        station: currentReportStation?.id,
        available: true,
      })
      .then((res) => {
        if (res?.data?.results) {
          setDrivers(res.data.results);
          setLoading(false);
        }
      })
      .catch(() => {
        handleClearDrivers();
      });
  };

  const handleChangeFleetSize = async (event: ChangeEvent<{ value: any }>) => {
    const size = fleetSizes.find((el) => el.id === +event.target.value);
    if (size) {
      await api
        .setSizeToAssignment(driverVanAssignmentId, {
          date: currentReportDate,
          station: currentReportStation?.id,
          fleet_size: size?.id,
        })
        .then((res) => {
          if (res?.data) setRowData({ ...rowData, van: { ...res.data, fleet_size: size } });
        })
        .catch(() => {
          dispatch(openModal(`no-vans-modal-${recordId}`));
          setRowData({ ...rowData, van: null });
        });
      // GET UPDATED DATA FROM BACK
      if (reportId) dispatch(getReportAction(reportId, false));
    }
  };

  const handleChangeDriver = async (event: ChangeEvent<{ value: any }>) => {
    const driverId = event.target.value;
    if (recordId) {
      if (driverId) {
        const currentDriver = drivers.find((el: DriverData) => el.id === +driverId);
        handleClearDrivers();
        await api.setDriverToAssignment({ record: recordId, driver: driverId }).then((res) => {
          if (res.data?.id) {
            setRowData({ ...rowData, driver: driverId, van: null });
            setDriverVanAssignmentId(res.data?.id);
            setCurrentDriver(currentDriver);
          }
        });
      } else if (driverId === 0 && driverVanAssignmentId) {
        await api.deleteAssignment(driverVanAssignmentId).then(() => {
          setRowData({ ...rowData, driver: driverId, van: null });
          setDriverVanAssignmentId(0);
          setCurrentDriver(undefined);
        });
      } else {
        setRowData({ ...rowData, driver: driverId, van: null });
        setDriverVanAssignmentId(0);
        setCurrentDriver(undefined);
      }
    }
    // GET UPDATED DATA FROM BACK
    if (reportId) dispatch(getReportAction(reportId, false));
  };

  const handleChangeSecondDriver = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.second_driver?.id) {
      handleUpdateRow({ second_driver_id: event.target.value === 0 ? null : event.target.value });
    }
  };

  const handleChangeRole = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.role) {
      handleUpdateRow({ role: event.target.value });
    }
  };

  const handleChangeWave = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.wave_time) {
      handleUpdateRow({ wave_time: event.target.value });
    }
  };

  const handleChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof ReportRecord;
    if (event.target.value !== rowData?.[name]) {
      handleUpdateRow({ [event.target.name]: event.target.checked });
    }
  };

  const inputTimeValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof ReportRecord;
    if (event.target.value !== rowData?.[name]) {
      handleUpdateRow({ [event.target.name]: event.target.value });
    }
  };

  const inputValueBlur = (event: FocusEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof ReportRecord;
    if (event.target.value === "" && rowData?.[name] === null) return;
    if (event.target.value !== rowData?.[name]) {
      handleUpdateRow({ [event.target.name]: event.target.value });
    }
  };

  const inputNumberValueChange = useCallback(
    (name: keyof ReportRecord, value: ReportRowValue) => {
      if (value === "" && rowData?.[name] === null) return;
      if (value === "" && rowData?.[name] !== null) {
        handleUpdateRow({ [name]: null });
        return;
      }
      if (+value! !== rowData?.[name]) {
        handleUpdateRow({ [name]: +value! });
        return;
      }
    },
    [rowData, handleUpdateRow],
  );

  const rescueValueChange = (value: ReportRowValue) => {
    if (value === "" && rowData?.rescue_given === null) return;
    if (value === "" && rowData?.rescue_given !== null) {
      handleUpdateRow({ rescue_given: null, rescue: false });
      return;
    }
    if (value !== rowData?.rescue_given) {
      handleUpdateRow({ rescue_given: +value!, rescue: Boolean(+value!) });
      return;
    }
  };

  const driver = rowData.driver?.id ? rowData.driver : currentDriver;

  const driverLabel = driver?.id ? (
    <MenuItem key={driver?.id} value={driver?.id}>
      {driver?.first_name} {driver?.last_name}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      Unassigned driver
    </MenuItem>
  );

  const driverSecondLabel = rowData?.second_driver?.id ? (
    <MenuItem key={rowData.second_driver?.id} value={rowData.second_driver?.id}>
      {rowData.second_driver?.first_name} {rowData.second_driver?.last_name}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      Unassigned driver
    </MenuItem>
  );

  const NO_FLEET_SIZE = "no data";

  const renderValues = {
    index: index + 1,
    van_group: rowData?.van?.van_group?.name || rowData.van?.van_group_name,
    van_number: rowData.van?.number,
    fleet_size: rowData.van?.fleet_size?.id ? rowData.van?.fleet_size?.id : NO_FLEET_SIZE,
    transporter_id:
      driver?.transporter_id !== "none" ? driver?.transporter_id : renderTextTransporterId,
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => inputNumberValueChange("cod", codValue), 500);
    return () => clearTimeout(timeoutId);
  }, [codValue, inputNumberValueChange]);

  return (
    <TableRow className={classes.tableRow}>
      <ModalWindow
        noButton
        modal_name={`no-vans-modal-${recordId}`}
        modalTitle={"No Free Vans"}
        modalText={`
          There are no free vans for current driver with such fleet size. 
          Try select this driver again with another fleet size or connect
          with administrator!
        `}
      >
        <NoVansDialog />
      </ModalWindow>

      <ModalWindow
        noButton
        modal_name={`duplicate-vans-modal-${recordId}`}
        modalTitle={"Duplicate Vans"}
        modalText={duplicateVansData?.message ? duplicateVansData?.message : duplicateVansData}
      >
        <DuplicateVansDialog data={duplicateVansData} />
      </ModalWindow>
      <TableCell
        className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}
        style={{ width: 50 }}
      >
        <IconButton
          disabled={conditionForDisabledEdit}
          onClick={handleDeleteRow}
          style={{ padding: "5px" }}
        >
          <CloseIcon color={conditionForDisabledEdit ? "disabled" : "secondary"} />
        </IconButton>
      </TableCell>
      <TableCell
        className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}
        style={{ width: 50 }}
      >
        {renderValues.index}
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
        {renderValues.van_group}
      </TableCell>
      <TableCell className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}>
        {renderValues.van_number || (
          <TextField
            name="fleet_number"
            inputProps={{ style: { textAlign: "center" } }}
            defaultValue={rowData.fleet_number || ""}
            onBlur={(e: OnBlurEvent) => inputNumberValueChange("fleet_number", e.target.value)}
            className={classes.input}
            disabled={conditionForDisabledEdit || Boolean(renderValues?.van_number)}
            onKeyPress={inputNumberValidate}
            onPaste={inputNumberValidateOnPaste}
            inputRef={ref}
          />
        )}
      </TableCell>
      <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Fleet Size"
          name="size"
          disabled={conditionForDisabledEdit || !rowData.driver}
          value={renderValues.fleet_size}
          onChange={handleChangeFleetSize}
        >
          {renderValues.fleet_size === NO_FLEET_SIZE && (
            <MenuItem key={0} value={NO_FLEET_SIZE} selected disabled>
              No selection
            </MenuItem>
          )}

          {fleetSizes.length > 0
            ? fleetSizes.map((el: FleetSizeType) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.title}
                </MenuItem>
              ))
            : null}
        </Select>
      </TableCell>
      <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Role"
          name="role"
          disabled={conditionForDisabledEdit}
          value={rowData.role}
          onChange={handleChangeRole}
        >
          {roleDa.map((el: { name: string; value: number; id: number }) => (
            <MenuItem key={el.id} value={el.value}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="route"
          defaultValue={rowData.route}
          disabled={conditionForDisabledEdit}
          onBlur={inputValueBlur}
        />
      </TableCell>
      <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Wave"
          name="wave_time"
          disabled={conditionForDisabledEdit}
          value={rowData.wave_time}
          onChange={handleChangeWave}
        >
          {rowData.wave_time === null && (
            <MenuItem key={0} value={NO_FLEET_SIZE} selected disabled>
              -
            </MenuItem>
          )}

          {waveDa.map((el) => (
            <MenuItem key={el.id} value={el.value}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell className={classes.tableCellBigSticky}>
        <Select
          key="select_da_1"
          className={classes.selectFieldBig}
          label="Select DA"
          disabled={conditionForDisabledEdit}
          value={rowData.driver?.id || rowData.driver || currentDriver?.id || 0}
          onOpen={handleGetDrivers}
          onChange={handleChangeDriver}
          onClose={handleClearDrivers}
        >
          <MenuItem key={0} value={0}>
            Unassigned driver
          </MenuItem>
          {drivers.length === 0 ? driverLabel : null}
          {loading ? (
            <MenuItem key="loading" disabled>
              <CircularProgress />
            </MenuItem>
          ) : (
            drivers.map((el: DriverData) => (
              <MenuItem key={el.id} value={el.id}>
                {el.first_name} {el.last_name}
              </MenuItem>
            ))
          )}
        </Select>
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
        {getCurrentAgency(reportRecord?.driver?.station_spots, currentReportStation?.id)}
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
        {renderValues.transporter_id}
      </TableCell>

      <TableCell className={classes.tableCellBig}>
        <Select
          key="select_da_2"
          className={classes.selectFieldBig}
          label="Select DA 2"
          disabled={conditionForDisabledEdit}
          value={rowData?.second_driver?.id || 0}
          onOpen={handleGetDrivers}
          onChange={handleChangeSecondDriver}
          onClose={handleClearDrivers}
        >
          <MenuItem key={0} value={0}>
            Unassigned driver
          </MenuItem>
          {drivers.length === 0 ? driverSecondLabel : null}
          {loading ? (
            <MenuItem key="loading" disabled>
              <CircularProgress />
            </MenuItem>
          ) : (
            drivers.map((el: DriverData) => (
              <MenuItem key={el.id} value={el.id}>
                {el.first_name} {el.last_name}
              </MenuItem>
            ))
          )}
        </Select>
      </TableCell>
      <TableCell className={classes.tableCellBig}>
        {getCurrentAgency(rowData?.second_driver?.station_spots, currentReportStation?.id)}
      </TableCell>
      <TableCell className={classes.tableCellComment}>
        <TextField
          name="actual_DA"
          disabled={conditionForDisabledEdit}
          defaultValue={rowData.actual_DA}
          onBlur={inputValueBlur}
        />
      </TableCell>

      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="rabbit"
          defaultValue={rowData.rabbit}
          disabled={conditionForDisabledEdit}
          onBlur={inputValueBlur}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="dolly_serial_number"
          disabled={conditionForDisabledEdit}
          defaultValue={rowData.dolly_serial_number}
          onBlur={inputValueBlur}
        />
      </TableCell>

      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.dolly}
          onChange={handleChangeCheckBox}
          name="dolly"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.ontime_attendance}
          onChange={handleChangeCheckBox}
          name="ontime_attendance"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.launchpad_support}
          onChange={handleChangeCheckBox}
          name="launchpad_support"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.morning_checklist}
          onChange={handleChangeCheckBox}
          name="morning_checklist"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox checked={rowData.rescue} name="rescue" disabled />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.violation}
          onChange={handleChangeCheckBox}
          name="violation"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.defects}
          onChange={handleChangeCheckBox}
          name="defects"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>

      <TableCell className={classes.tableCellSmall}>
        <TextField
          className={classes.input}
          name="number_of_stops"
          defaultValue={rowData.number_of_stops}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("number_of_stops", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="number_of_packages"
          defaultValue={rowData.number_of_packages}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("number_of_packages", e.target.value)}
          className={classes.input}
          disabled={conditionForDisabledEdit}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="first_stop_time"
          type="time"
          defaultValue="00:00"
          value={rowData.first_stop_time}
          onChange={inputTimeValueChange}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="planned_end_time"
          type="time"
          defaultValue="00:00"
          value={rowData.planned_end_time}
          onChange={inputTimeValueChange}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="actual_end_time"
          type="time"
          defaultValue="00:00"
          value={rowData.actual_end_time}
          onChange={inputTimeValueChange}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="rescue_given"
          defaultValue={rowData.rescue_given}
          onBlur={(e: OnBlurEvent) => rescueValueChange(e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="rescue_taken"
          defaultValue={rowData.rescue_taken}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("rescue_taken", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="uta"
          defaultValue={rowData.uta}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("uta", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="utl"
          defaultValue={rowData.utl}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("utl", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="nsl"
          defaultValue={rowData.nsl}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("nsl", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="bc"
          defaultValue={rowData.bc}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("bc", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="missing"
          defaultValue={rowData.missing}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("missing", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="damage"
          defaultValue={rowData.damage}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("damage", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="fdd"
          defaultValue={rowData.fdd}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("fdd", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="missort"
          defaultValue={rowData.missort}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("missort", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="oc"
          defaultValue={rowData.oc}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("oc", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="others"
          defaultValue={rowData.others}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("others", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell
        className={classes.tableCellSmall}
        style={{ background: "#e0e0e0", borderLeft: `1px solid ${Colors.MAIN}` }}
      >
        <NumericFormat
          name="cod"
          thousandSeparator={true}
          value={codValue}
          className={classes.input}
          prefix={"$"}
          disabled={conditionForDisabledEdit}
          customInput={TextField}
          onValueChange={(values, sourceInfo) => {
            setCodValue(values.floatValue);
          }}
        />
      </TableCell>
    </TableRow>
  );
};
